import { CommissioningFormValues } from "nvent-web/types/CommissioningFormValues";
import { DetailedProject } from "nvent-web/types/DetailedProject";
import { NewProjectFormValues } from "nvent-web/types/NewProjectFormValues";
import { Project } from "nvent-web/types/Project";
import { SearchState } from "nvent-web/types/SearchState";

import Resource from "./Resource";
export interface ProjectFilters {
  search?: string;
  state?: SearchState;
}

export default class ProjectsResource extends Resource {
  getAll() {
    return this.http.get<Project[]>("projects");
  }
  create(data: NewProjectFormValues) {
    return this.http.post<Project>("projects", data);
  }
  getOne(id: number) {
    return this.http.get<DetailedProject>(`projects/${id}`);
  }
  updateOne(data: NewProjectFormValues, id: number) {
    return this.http.patch<DetailedProject>(`projects/${id}`, data);
  }
  remove(projectId: number) {
    return this.http.delete(`projects/${projectId}`);
  }
  sendCommission(projectId: number, data: CommissioningFormValues) {
    return this.http.post(`projects/${projectId}/report`, data);
  }
  async downloadCommissionReport(projectId: number) {
    return this.http.get<{ url: string }>(`projects/${projectId}/report`);
  }
  async downloadBillOfMaterials(projectId: number) {
    return this.http.get<{ url: string }>(`projects/${projectId}/bom`);
  }
  archive(projectId: number) {
    return this.http.patch(`projects/${projectId}/archive`);
  }
  copy(projectId: number) {
    return this.http.post(`projects/${projectId}/duplicate`);
  }
  search({ state, search }: ProjectFilters) {
    return this.http.get("projects", {
      params: { state, q: search },
    });
  }
}
