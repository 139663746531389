import classNames from "classnames";
import format from "date-fns/format";
import { Component } from "react";
import { FormattedMessage } from "react-intl";
import MediaQuery from "react-responsive";

import Card from "nvent-web/components/Card";
import DesktopCard from "nvent-web/components/Card/components/DesktopCard";
import ProgressFull from "nvent-web/components/Progress/FullProgress";
import { gridBreakpoints } from "nvent-web/lib/constants";
import { DetailedProject } from "nvent-web/types/DetailedProject";

import style from "./ProjectDetailsCard.module.scss";

interface ProjectDetailsCardProps {
  project: DetailedProject;
  actions: React.ReactNode[];
  toggleExpanded: () => void;
  isExpanded: boolean;
}

class ProjectDetailsCard extends Component<ProjectDetailsCardProps> {
  renderCardDetails() {
    const { clientName, clientPhoneNumber, createdAt, progress, address, rooms, referenceNumber, finished } =
      this.props.project;

    const referenceNumberValue = referenceNumber.match(/.{1,3}/g);

    return (
      <div className={style.details}>
        <div className={style.row}>
          <p className={style.referenceNumberLabel}>
            <FormattedMessage id={"projects.details.referenceNumber"} />
          </p>
          <span className={style.referenceNumberValue}>
            {referenceNumberValue ? referenceNumberValue.join(" ") : ""}
          </span>
        </div>
        <div className={style.row}>
          <p className={style.startLabel}>
            <FormattedMessage id={"projects.details.startProject"} />
          </p>
          <span className={style.startValue}>{format(createdAt, "dd-MM-yyyy")}</span>
        </div>
        <hr />
        <div className={classNames(style.row, style.clientHeaderRow)}>
          <p className={style.clientHeader}>
            <FormattedMessage id={"projects.details.projectInfo"} />
          </p>
        </div>
        <div className={classNames(style.row, style.clientNameRow)}>
          <p className={style.clientName}>{clientName}</p>
          <a href={`tel:${clientPhoneNumber}`} className={style.clientPhoneNumber}>
            {clientPhoneNumber}
          </a>
        </div>

        <div className={classNames(style.row, style.roomsCountRow)}>
          <p className={style.clientAddress}>{address.streetAddress}</p>
          <span className={style.clientRooms}>
            <FormattedMessage id="projects.details.rooms" values={{ count: rooms.length }} />
          </span>
        </div>
        {!finished && (
          <div className={classNames(style.row, style.progressRow)}>
            <ProgressFull progress={progress} />
          </div>
        )}
      </div>
    );
  }

  render() {
    const { actions, isExpanded, toggleExpanded } = this.props;
    const { id, name, finished } = this.props.project;

    return (
      <>
        <MediaQuery minWidth={gridBreakpoints.lg}>
          {(isDesktop) => {
            if (isDesktop) {
              return (
                <DesktopCard
                  actions={actions}
                  className={{
                    wrapper: style.desktopCardWrapper,
                    title: style.desktopCardTitle,
                  }}
                  title={name}
                  isFinished={finished}
                >
                  {this.renderCardDetails()}
                </DesktopCard>
              );
            } else {
              return (
                <Card
                  to={`/projects/${id}`}
                  title={name}
                  actions={actions}
                  toggleExpanded={toggleExpanded}
                  isExpanded={isExpanded}
                  classNames={{ card: style.card }}
                  isFinished={finished}
                >
                  {this.renderCardDetails()}
                </Card>
              );
            }
          }}
        </MediaQuery>
      </>
    );
  }
}

export default ProjectDetailsCard;
