import { action, computed, observable } from "mobx";
import { fromPromise, IPromiseBasedObservable, PENDING } from "mobx-utils";

import Api from "nvent-web/services/Api";
import { Photo } from "nvent-web/types/Photo";

import { RoomsStore } from "./Rooms";

export class PhotosStore {
  @observable newPhotosPromise?: IPromiseBasedObservable<Photo[]>;
  @observable deletePhotoPromise?: IPromiseBasedObservable<Photo[]>;

  constructor(
    private rooms: RoomsStore,
    private api: Api
  ) {}

  @computed
  get arePhotosCreating(): boolean {
    return Boolean(this.newPhotosPromise && this.newPhotosPromise.state === PENDING);
  }

  @action.bound
  async createPhotos(projectId: number, roomId: number, images: File[]) {
    this.newPhotosPromise = fromPromise(this.api.photos.create(projectId, roomId, images).then(({ data }) => data));

    await this.newPhotosPromise;
    await this.rooms.getRoom(projectId, roomId);

    return this.newPhotosPromise;
  }

  @action.bound
  async deletePhoto(projectId: number, roomId: number, imageId: number) {
    this.deletePhotoPromise = fromPromise(this.api.photos.delete(projectId, roomId, imageId).then(({ data }) => data));

    await this.deletePhotoPromise;
    await this.rooms.getRoom(projectId, roomId);

    return this.deletePhotoPromise;
  }
}
