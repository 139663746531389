import { History } from "history";
import { inject, observer } from "mobx-react";
import { Component } from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "recompose";

import RoomForm from "nvent-web/components/RoomForm";
import { RoomsStore } from "nvent-web/stores/Rooms";
import { NewRoomFormValues } from "nvent-web/types/NewRoomFormValues";

interface AddRoomsParams {
  projectId: string;
}

interface AddRoomFormInnerProps extends RouteComponentProps<AddRoomsParams>, InjectedIntlProps {
  history: History;
  rooms: RoomsStore;
}

const initialValues: NewRoomFormValues = {
  name: "",
  area: null,
  installableArea: null,
  floorConstruction: null,
  floorFinish: null,
  areaType: null,
};

class AddRoomFormInner extends Component<AddRoomFormInnerProps> {
  render() {
    return (
      <RoomForm
        isSubmitting={this.props.rooms.isRoomCreating}
        onCancel={this.handleCancel}
        initialValues={initialValues}
        handleSubmit={this.handleSubmit}
      />
    );
  }

  private handleCancel = () => {
    this.props.history.goBack();
  };

  private handleSubmit = (formValues: NewRoomFormValues) => {
    const { rooms } = this.props;

    rooms.createRoom(formValues, parseInt(this.props.match.params.projectId, 10));
  };
}

const AddRoomForm = compose<AddRoomFormInnerProps, Record<string, unknown>>(
  injectIntl,
  withRouter,
  inject("rooms"),
  observer
)(AddRoomFormInner);

export default AddRoomForm;
