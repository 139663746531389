import { Component } from "react";

import { EditAction } from "nvent-web/components/Actions/EditAction";
import { GetBOMAction } from "nvent-web/components/Actions/GetBOMAction";
import { GetPDFAction } from "nvent-web/components/Actions/GetPDFAction";
import { DetailedProject } from "nvent-web/types/DetailedProject";

import ProjectDetailsCard from "../ProjectDetailsCard";

interface ProjectProps {
  data: DetailedProject;
  onGetBOM: (id: number) => void;
  onEdit: (id: number) => void;
  onGetPDF: (id: number) => void;
  toggleExpanded: () => void;
  isExpanded: boolean;
  hasProducts: boolean;
  reportDownloading?: boolean;
  billOfMaterialsDownloading?: boolean;
}

class ProjectDetailsItem extends Component<ProjectProps> {
  render() {
    const { isExpanded, toggleExpanded, hasProducts, reportDownloading, billOfMaterialsDownloading } = this.props;

    return (
      <>
        <ProjectDetailsCard
          project={this.props.data}
          actions={[
            <EditAction onClick={this.handleEdit} key="edit" />,
            <GetPDFAction onClick={this.handleGetPDF} key="getPDF" loading={reportDownloading} />,
            <GetBOMAction
              onClick={this.handleGetBOM}
              disabled={!hasProducts}
              key="getBOM"
              loading={billOfMaterialsDownloading}
            />,
          ]}
          toggleExpanded={toggleExpanded}
          isExpanded={isExpanded}
        />
      </>
    );
  }

  private handleEdit = () => {
    this.props.onEdit(this.props.data.id);
  };

  private handleGetBOM = () => {
    this.props.onGetBOM(this.props.data.id);
  };

  private handleGetPDF = () => {
    this.props.onGetPDF(this.props.data.id);
  };
}

export default ProjectDetailsItem;
