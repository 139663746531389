import { History } from "history";
import { inject } from "mobx-react";
import qs from "query-string";
import { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";

import { UserStore } from "nvent-web/stores/User";
import { BuildingType } from "nvent-web/types/BuildingType";
import { SelectionGuideFormValues } from "nvent-web/types/SelectionGuideFormValues";

import RoomDetailsStepForm, {
  roomDetailsValidationSchema,
} from "../SelectionGuide/components/RoomDetailsStep/components/RoomDetailsStepForm/RoomDetailsStepForm";
import StepHeading from "../SelectionGuide/components/StepHeading/StepHeading";

interface RoomDetailsStepInnerProps extends RouteComponentProps {
  history: History;
  user: UserStore;
}

const emptyValues: SelectionGuideFormValues = {
  floorConstruction: null,
  floorFinish: null,
  area: null,
  installableArea: null,
  quickHeating: false,
  areaType: null,
};

export class RoomDetailsStepInner extends Component<RoomDetailsStepInnerProps> {
  cancelRoomEdit = () => {
    this.props.history.push("/selection-guide");
  };

  submit = (values: SelectionGuideFormValues) => {
    const { buildingType, countryCode, projectName } = qs.parse(this.props.history.location.search);

    this.props.history.push({
      pathname: `/selection-guide/recommendations`,
      search: qs.stringify({
        ...values,
        buildingType,
        projectName,
        countryCode,
      }),
    });
  };

  render() {
    const { buildingType, countryCode } = qs.parse(this.props.history.location.search);

    if (!buildingType) {
      return <Redirect to={`${this.props.match.url}/insulation`} />;
    }

    const { user } = this.props;

    const queryValues = roomDetailsValidationSchema.cast(qs.parse(this.props.history.location.search));

    const initialValues = {
      ...emptyValues,
      ...queryValues,
    };

    return (
      <>
        <StepHeading step={2} />
        <RoomDetailsStepForm
          isSubmitting={false}
          onCancel={this.cancelRoomEdit}
          cancelButtonText={<FormattedMessage id="actions.back" />}
          initialValues={initialValues}
          handleSubmit={this.submit}
          buildingType={buildingType as BuildingType}
          countryCode={(user.isReady ? user.companyAddress.countryCode : countryCode) as string}
        />
      </>
    );
  }
}

export const RoomDetailsStep = compose<RoomDetailsStepInnerProps, Record<string, unknown>>(
  withRouter,
  inject("user")
)(RoomDetailsStepInner);
