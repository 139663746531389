import { action, computed, observable, when } from "mobx";
import { createTransformer, fromPromise, PENDING } from "mobx-utils";

import Api from "nvent-web/services/Api";
import { createInitialFromPromise } from "nvent-web/utils/createInitialFromPromise";
import { Promotion } from "nvent-web-admin/types/Promotion";

import { UserStore } from "./User";

export class PromotionsStore {
  @observable promotionsPromise = createInitialFromPromise<Promotion[]>([]);

  constructor(
    private user: UserStore,
    private api: Api
  ) {
    when(
      () => user.isReady,
      async () => {
        await this.loadPromotions();
      }
    );
  }

  @computed
  get arePromotionsLoading(): boolean {
    return this.promotionsPromise.state === PENDING;
  }

  @computed
  get promotions(): Promotion[] {
    return (
      this.promotionsPromise.case({
        fulfilled: (promotions) => promotions,
      }) || []
    );
  }

  @computed
  get promotionsForCurrentUser(): Promotion[] {
    return this.promotionsByCountryCode(this.user.companyAddress.countryCode).filter(({ availability, status }) => {
      if (status === "inactive") {
        return false;
      }

      if (availability === "both") {
        return true;
      }

      return this.user.certifiedProNumber
        ? availability === "only_certified_pro"
        : availability === "only_non_certified_pro";
    });
  }

  @computed get promotionsByCountryCode() {
    return createTransformer((countryCode: string) =>
      this.promotions.filter((promotion) => promotion.countryCode.toLowerCase() === countryCode.toLowerCase())
    );
  }

  @action.bound
  async loadPromotions() {
    try {
      const response = await this.api.promotions.getAll();
      this.promotionsPromise = fromPromise(Promise.resolve(response.data));
      return this.promotionsPromise;
    } catch (error) {
      console.error(error);
    }
  }

  @action.bound
  async deletePromotion(id: string) {
    await this.api.promotions.delete(id);
  }

  @action.bound
  async loadPromotionsForCountry(countryCode: string) {
    try {
      const { data } = await this.api.promotions.getAll(countryCode);
      return data;
    } catch (error) {
      console.error(error);
    }
  }
}
