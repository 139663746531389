import * as ReactGA from "react-ga";

export const initializeGoogleAnalytics = () => {
  const gaApiKey = process.env.REACT_APP_GA_API_KEY;

  if (gaApiKey) {
    ReactGA.initialize(gaApiKey, {
      gaOptions: { siteSpeedSampleRate: 100 },
    });
  }
};
