import { Component } from "react";
import { FormattedMessage } from "react-intl";

import { CopyAction } from "nvent-web/components/Actions/CopyAction";
import { EditAction } from "nvent-web/components/Actions/EditAction";
import { RemoveAction } from "nvent-web/components/Actions/RemoveAction";
import ConfirmationModal from "nvent-web/components/Modal/ConfirmationModal";
import { DetailedRoom } from "nvent-web/types/DetailedRoom";

import RoomCard from "../RoomCard";

interface RoomItemProps {
  projectId: number;
  data: DetailedRoom;
  onRemove: (id: number) => void;
  onEdit: (id: number) => void;
  onCopy: (id: number) => void;
  disableActions: boolean;
  toggleExpanded: () => void;
  isExpanded: boolean;
}

interface RoomItemState {
  isRemoveConfirmationOpen: boolean;
}

class RoomItem extends Component<RoomItemProps, RoomItemState> {
  state = { isRemoveConfirmationOpen: false };

  render() {
    const { isRemoveConfirmationOpen } = this.state;
    const { projectId, isExpanded, toggleExpanded, disableActions } = this.props;

    const roomActions = disableActions
      ? []
      : [
          <RemoveAction onClick={this.openRemoveConfirmation} key="remove" />,
          <CopyAction onClick={this.handleCopy} key="copy" />,
          <EditAction onClick={this.handleEdit} key="edit" />,
        ];

    return (
      <>
        <RoomCard
          project={this.props.data}
          projectId={projectId}
          actions={roomActions}
          toggleExpanded={toggleExpanded}
          isExpanded={isExpanded}
          isFinished={disableActions}
        />
        <ConfirmationModal
          isOpen={isRemoveConfirmationOpen}
          handleClose={this.closeRemoveConfirmation}
          center
          title={<FormattedMessage id={"rooms.removeModal.title"} />}
          description={<FormattedMessage id={"rooms.removeModal.description"} />}
          onCancel={this.closeRemoveConfirmation}
          onConfirm={this.handleRemove}
        />
      </>
    );
  }

  private handleEdit = () => {
    const {
      onEdit,
      data: { id },
    } = this.props;

    onEdit(id);
  };

  private handleRemove = () => {
    const {
      onRemove,
      data: { id },
    } = this.props;

    onRemove(id);
  };

  private handleCopy = () => {
    const {
      onCopy,
      data: { id },
    } = this.props;

    onCopy(id);
  };

  private openRemoveConfirmation = () => {
    this.setState({
      isRemoveConfirmationOpen: true,
    });
  };

  private closeRemoveConfirmation = () => {
    this.setState({
      isRemoveConfirmationOpen: false,
    });
  };
}

export default RoomItem;
