import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import classNames from "classnames";
import { Component, createRef, MouseEvent, RefObject } from "react";
import ReactDOM from "react-dom";

import style from "./Modal.module.scss";

export interface ModalProps {
  isOpen: boolean;
  handleClose: () => void;
  bodyClass?: string;
  center: boolean;
  wide?: boolean;
}

class Modal extends Component<ModalProps> {
  private modalWrapperRef: RefObject<HTMLDivElement>;
  private modalRoot: HTMLElement | null;
  private el: HTMLDivElement;
  private targetElement: HTMLElement | null;

  constructor(props: ModalProps) {
    super(props);
    this.modalRoot = document.getElementById("modalRoot");
    this.el = document.createElement("div");
    this.modalWrapperRef = createRef();
    this.targetElement = null;
  }

  componentDidMount() {
    this.targetElement = this.modalWrapperRef.current;

    if (this.targetElement) {
      disableBodyScroll(this.targetElement);
    }

    if (this.modalRoot) {
      this.modalRoot.appendChild(this.el);
    }
  }

  componentWillUnmount() {
    if (this.modalRoot) {
      this.modalRoot.removeChild(this.el);
    }
    clearAllBodyScrollLocks();
  }

  handleClickOutside = (event: MouseEvent<HTMLDivElement>) => {
    const { handleClose } = this.props;
    if (this.modalWrapperRef && this.modalWrapperRef.current === event.target) {
      handleClose();
    }
  };

  render() {
    const { isOpen, bodyClass, center, children, wide = false } = this.props;

    return ReactDOM.createPortal(
      <div>
        {isOpen && (
          <div
            className={classNames(style.overlay, { [style.centered]: center })}
            ref={this.modalWrapperRef}
            onClick={this.handleClickOutside}
          >
            <div
              className={classNames(style.modal, bodyClass, {
                [style.centered]: center,
                [style.wide]: wide,
              })}
            >
              <div className={style.modalInner}>{children}</div>
            </div>
          </div>
        )}
      </div>,
      this.el
    );
  }
}

export default Modal;
