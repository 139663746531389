import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestTransformer,
  AxiosResponseTransformer,
  InternalAxiosRequestConfig,
} from "axios";
import humps from "humps";
import debounce from "lodash-es/debounce";
import qs from "query-string";
import { FormattedMessage } from "react-intl";

import { NotificationsStore } from "nvent-web/stores/Notifications";
import { buildTransformers } from "nvent-web/utils/transformers";
import ProductsAdminResource from "nvent-web-admin/resources/ProductsResource";
import ProjectsAdminResource from "nvent-web-admin/resources/ProjectResource";
import UsersAdminResource from "nvent-web-admin/resources/UsersResource";

import { AppAuth0 } from "./auth0/useCreateAppAuth0";
import AccessoryResource from "./resources/AccessoryResource";
import { AuthResource } from "./resources/AuthResource";
import OptionsResource from "./resources/OptionsResource";
import PhotoResource from "./resources/PhotoResource";
import ProductCatalogResource from "./resources/ProductCatalogResource";
import ProductResource from "./resources/ProductResource";
import { ProfileResource } from "./resources/ProfileResource";
import ProjectsResource from "./resources/ProjectResource";
import PromotionsResource from "./resources/PromotionsResource";
import RoomsResource from "./resources/RoomResource";
import { TeamInstallersResource } from "./resources/TeamInstallersResource";
import { TeamProjectsResource } from "./resources/TeamProjectsResource";
import ThermostatResource from "./resources/ThermostatResource";

export default class Api {
  setAuth0!: (auth0: AppAuth0) => void;

  readonly admin: {
    users: UsersAdminResource;
    projects: ProjectsAdminResource;
    products: ProductsAdminResource;
  };

  readonly auth: AuthResource;
  readonly profile: ProfileResource;
  readonly projects: ProjectsResource;
  readonly rooms: RoomsResource;
  readonly products: ProductResource;
  readonly thermostat: ThermostatResource;
  readonly accessories: AccessoryResource;
  readonly productCatalog: ProductCatalogResource;
  readonly photos: PhotoResource;
  readonly options: OptionsResource;
  readonly promotions: PromotionsResource;
  readonly team: {
    installers: TeamInstallersResource;
    projects: TeamProjectsResource;
  };

  readonly http: AxiosInstance;

  private auth0Promise: Promise<AppAuth0>;

  private showNetworkError = debounce(() => {
    this.notifications.createError(<FormattedMessage id="error.networkError" />);
  }, 250);

  constructor(
    baseURL: string,
    private notifications: NotificationsStore
  ) {
    this.http = axios.create({
      baseURL,
      transformRequest: buildTransformers<AxiosRequestTransformer>(
        (data) => (data instanceof FormData ? data : humps.decamelizeKeys(data)),
        axios.defaults.transformRequest
      ),
      transformResponse: buildTransformers<AxiosResponseTransformer>(axios.defaults.transformResponse, (data) =>
        data instanceof Blob ? data : humps.camelizeKeys(data)
      ),
    });
    this.http.interceptors.request.use(this.addAccessToken.bind(this));
    this.http.interceptors.response.use(undefined, this.errorResponseHandler);

    this.auth = new AuthResource(this);
    this.profile = new ProfileResource(this);
    this.projects = new ProjectsResource(this);
    this.rooms = new RoomsResource(this);
    this.products = new ProductResource(this);
    this.thermostat = new ThermostatResource(this);
    this.productCatalog = new ProductCatalogResource(this);
    this.photos = new PhotoResource(this);
    this.options = new OptionsResource(this);
    this.accessories = new AccessoryResource(this);
    this.promotions = new PromotionsResource(this);

    this.admin = {
      users: new UsersAdminResource(this),
      projects: new ProjectsAdminResource(this),
      products: new ProductsAdminResource(this),
    };

    this.team = {
      installers: new TeamInstallersResource(this),
      projects: new TeamProjectsResource(this),
    };

    this.auth0Promise = new Promise<AppAuth0>((resolve) => {
      this.setAuth0 = (auth0) => {
        // Replace the promise so that setAuth0 can be called multiple times
        // and the promise always resolves with the latest value
        this.auth0Promise = Promise.resolve(auth0);
        resolve(auth0);
      };
    });
  }

  async getAccessToken() {
    const { isAuthenticated, getApiTokenSilently } = await this.auth0Promise;

    if (isAuthenticated) {
      return await getApiTokenSilently();
    }

    return null;
  }

  async addAccessToken(config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> {
    if (!config.headers?.Authorization) {
      const accessToken = await this.getAccessToken();

      if (accessToken && config.headers) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }

    return config;
  }

  async download(config: { url: string; params?: { [key: string]: any } }) {
    const accessToken = await this.getAccessToken();
    const params = humps.decamelizeKeys({ ...config.params, token: accessToken });
    const url = `${this.http.defaults.baseURL || ""}${config.url}?${qs.stringify(params, { arrayFormat: "bracket" })}`;
    window.location.href = url;
  }

  private errorResponseHandler = (error: AxiosError) => {
    if (error.request && !error.response) {
      this.showNetworkError();
    }
    return Promise.reject(error);
  };
}
