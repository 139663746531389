import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import { Component } from "react";
import { FormattedMessage } from "react-intl";
import { compose } from "recompose";

import { CopyAction } from "nvent-web/components/Actions/CopyAction";
import { GetPDFAction } from "nvent-web/components/Actions/GetPDFAction";
import { RemoveAction } from "nvent-web/components/Actions/RemoveAction";
import ConfirmationModal from "nvent-web/components/Modal/ConfirmationModal";
import ProjectCard from "nvent-web/components/ProjectCard";
import * as logger from "nvent-web/services/logger";
import { NotificationsStore } from "nvent-web/stores/Notifications";
import { ProjectsStore } from "nvent-web/stores/Projects";
import { Project } from "nvent-web/types/Project";

import { ArchiveAction } from "../Actions/ArchiveAction";

export interface ProjectListItemProps {
  project: Project;
}

export interface ProjectListItemInnerProps extends ProjectListItemProps {
  projects: ProjectsStore;
  notifications: NotificationsStore;
}

interface ProjectListItemState {
  isArchiveConfirmationOpen: boolean;
  isRemoveConfirmationOpen: boolean;
}

export class ProjectListItemInner extends Component<ProjectListItemInnerProps, ProjectListItemState> {
  @observable
  private reportDownloading = false;

  constructor(props: ProjectListItemInnerProps) {
    super(props);

    this.state = {
      isArchiveConfirmationOpen: false,
      isRemoveConfirmationOpen: false,
    };
  }

  render() {
    const { project, projects } = this.props;
    const { isArchiveConfirmationOpen, isRemoveConfirmationOpen } = this.state;
    const { isExpanded, toggleExpanded } = projects.getLocalState(project.id);

    const actions = [
      project.finished ? (
        <ArchiveAction onClick={this.openArchiveConfirmation} key="archive" disabled={Boolean(project.archivedAt)} />
      ) : (
        <RemoveAction onClick={this.openRemoveConfirmation} key="remove" />
      ),
      <CopyAction onClick={this.copy} key="copy" />,
      <GetPDFAction onClick={this.downloadReport} key="commision" loading={this.reportDownloading} />,
    ];

    return (
      <>
        <ProjectCard project={project} actions={actions} toggleExpanded={toggleExpanded} isExpanded={isExpanded} />

        <ConfirmationModal
          isOpen={isArchiveConfirmationOpen}
          handleClose={this.closeArchiveConfirmation}
          center
          title={<FormattedMessage id={"projects.archiveModal.title"} />}
          description={<FormattedMessage id={"projects.archiveModal.description"} />}
          onCancel={this.closeArchiveConfirmation}
          onConfirm={this.archive}
        />
        <ConfirmationModal
          isOpen={isRemoveConfirmationOpen}
          handleClose={this.closeRemoveConfirmation}
          center
          title={<FormattedMessage id={"projects.removeModal.title"} />}
          description={<FormattedMessage id={"projects.removeModal.description"} />}
          onCancel={this.closeRemoveConfirmation}
          onConfirm={this.remove}
        />
      </>
    );
  }

  private copy = () => {
    this.props.projects.copyProject(this.props.project.id);
  };

  private downloadReport = async () => {
    const { projects, notifications } = this.props;
    this.reportDownloading = true;
    try {
      const { data } = await projects.downloadReport(this.props.project.id);
      if (window.open(data.url, "_blank")) {
        return;
      } else {
        window.location.href = data.url;
      }
    } catch (error) {
      logger.error(error);
      notifications.createError(<FormattedMessage id="error.reportDownloadFailed" />);
    } finally {
      this.reportDownloading = false;
    }
  };

  private archive = async () => {
    await this.props.projects.archiveProject(this.props.project.id);
    this.closeArchiveConfirmation();
    this.props.projects.getLocalState(this.props.project.id).toggleExpanded(false);
  };

  private remove = async () => {
    await this.props.projects.removeProject(this.props.project.id);
    this.closeRemoveConfirmation();
  };

  private openArchiveConfirmation = () => {
    this.setState({ isArchiveConfirmationOpen: true });
  };

  private closeArchiveConfirmation = () => {
    this.setState({ isArchiveConfirmationOpen: false });
  };

  private openRemoveConfirmation = () => {
    this.setState({ isRemoveConfirmationOpen: true });
  };

  private closeRemoveConfirmation = () => {
    this.setState({ isRemoveConfirmationOpen: false });
  };
}

export const ProjectListItem = compose<ProjectListItemInnerProps, ProjectListItemProps>(
  inject("projects", "notifications"),
  observer
)(ProjectListItemInner);
