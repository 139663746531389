import { observer } from "mobx-react";
import { FC, ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { LoadingSection } from "nvent-web/components/LoadingSection";
import { Project } from "nvent-web/types/Project";

import style from "./ProjectList.module.scss";
import { ProjectListItem } from "./ProjectListItem";

export interface ProjectListProps {
  projects: Project[];
  isLoading?: boolean;
  emptyMessage?: ReactNode;
}

export const ProjectList = observer<FC<ProjectListProps>>(
  ({ projects, isLoading: loading, emptyMessage = <FormattedMessage id="projects.noContent" /> }) => (
    <div className={style.root}>
      {loading && <LoadingSection />}
      {!loading && projects.length === 0 && <h2 className={style.noContent}>{emptyMessage}</h2>}

      {projects.map((project) => (
        <ProjectListItem key={project.id} project={project} />
      ))}
    </div>
  )
);
