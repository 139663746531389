import keyBy from "lodash-es/keyBy";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import { Component } from "react";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { compose } from "recompose";

import PageTitle from "nvent-web/App/components/PageTitle";
import LinkButton from "nvent-web/components/Button/LinkButton";
import { LoadingSection } from "nvent-web/components/LoadingSection";
import ProgressFull from "nvent-web/components/Progress/FullProgress";
import { ProductsStore } from "nvent-web/stores/Products";
import { ProjectsStore } from "nvent-web/stores/Projects";
import { RoomsStore } from "nvent-web/stores/Rooms";
import { TestType } from "nvent-web/types/Test";

import Test, { TestFormValues } from "./components/Test";
import TestProgress from "./components/TestProgress";
import style from "./Tests.module.scss";

interface ProductTestParams {
  projectId: string;
  roomId: string;
  productId: string;
}

interface TestsProps extends RouteComponentProps<ProductTestParams> {
  products: ProductsStore;
  projects: ProjectsStore;
  rooms: RoomsStore;
}

const stepsOrder: TestType[] = ["pre_installation", "post_installation", "after_floor_covering"];
const TEST_COUNT = stepsOrder.length;

export class Tests extends Component<TestsProps> {
  @observable step?: TestType;

  private get params() {
    const rawParams = this.props.match.params;

    return {
      ...rawParams,
      projectId: Number(rawParams.projectId),
      roomId: Number(rawParams.roomId),
      productId: Number(rawParams.productId),
    };
  }

  async componentDidMount() {
    await this.setStep();
  }

  async setStep() {
    const { products, projects, rooms } = this.props;
    const { projectId, roomId, productId } = this.params;

    projects.getProject(projectId);
    rooms.getRoom(projectId, roomId);

    const product = await products.getProduct(projectId, roomId, productId);

    const testsByStep = keyBy(product.tests, "type");

    const step = stepsOrder.find((stepType) => {
      const test = testsByStep[stepType];
      return !test;
    });

    this.step = step;
  }

  render() {
    const { singleProduct, isProductLoading } = this.props.products;
    const { projectDetails } = this.props.projects;
    const { roomDetails } = this.props.rooms;

    if (!singleProduct && isProductLoading) {
      return <LoadingSection />;
    }

    let allTestsAreComplete;
    if (singleProduct) {
      allTestsAreComplete = singleProduct.tests.filter((test) => test.completed).length === TEST_COUNT;
    }

    return (
      <>
        <PageTitle>
          <FormattedMessage id="tests.title" />
        </PageTitle>
        {projectDetails && roomDetails && allTestsAreComplete && (
          <Link to={`/projects/${projectDetails.id}/rooms/${roomDetails.id}`}>{roomDetails.name}</Link>
        )}
        {singleProduct && (
          <>
            <h2 className={style.productName}>{singleProduct.description}</h2>
            <ProgressFull
              progress={((100 / 3) * singleProduct.tests.filter((test) => test.completed).length) / 100}
              hideLabel
            />
            <TestProgress step={this.step} product={singleProduct} onTestItemClick={this.switchStep} />
            {this.step && <Test product={singleProduct} step={this.step} onSubmit={this.onSubmit} />}
            <div className={style.actions}>
              {projectDetails && roomDetails && allTestsAreComplete && (
                <LinkButton
                  className={style.button}
                  theme="primaryBlue"
                  to={`/projects/${projectDetails.id}/rooms/${roomDetails.id}`}
                >
                  <FormattedMessage id="actions.finish" />
                </LinkButton>
              )}
            </div>
          </>
        )}
      </>
    );
  }

  private switchStep = (step: TestType) => (this.step = step);

  private onSubmit = async (values: TestFormValues, step: string) => {
    const { products } = this.props;
    const { projectId, roomId, productId } = this.params;

    await products.testProduct(projectId, roomId, productId, values.heatResistance, values.insulationResistance, step);
    await this.setStep();
  };
}

export default compose<TestsProps, Record<string, unknown>>(inject("products", "projects", "rooms"), observer)(Tests);
