import { observer } from "mobx-react";
import { Component, ReactNode } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { compose } from "recompose";

import { ReactComponent as LogoutIcon } from "nvent-web/assets/svg/side-menu/logout.svg";
import { withAppAuth0 } from "nvent-web/services/auth0/AppAuth0Context";
import { AppAuth0 } from "nvent-web/services/auth0/useCreateAppAuth0";

import style from "./SidebarMenu.module.scss";

interface SidebarMenuInnerProps extends InjectedIntlProps {
  baseUrl: string;
  auth0: AppAuth0;
}

class SidebarMenuInner extends Component<SidebarMenuInnerProps> {
  render() {
    return (
      <nav className={style.menu}>
        {this.props.children}
        {this.renderLogOutItem()}
      </nav>
    );
  }

  private renderLogOutItem() {
    return (
      <button type="button" className={style.logoutItem} onClick={this.logOut}>
        <div className={style.simpleItem}>
          <div className={style.itemIcon}>
            <LogoutIcon />
          </div>
          <div className={style.itemText}>
            <FormattedMessage id="side.menu.logOut" />
          </div>
        </div>
      </button>
    );
  }

  private logOut = () => {
    this.props.auth0.logout();
  };
}

export const SidebarMenu = compose<SidebarMenuInnerProps, { children?: ReactNode }>(
  injectIntl,
  withAppAuth0,
  observer
)(SidebarMenuInner);
export default SidebarMenu;
