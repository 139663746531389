import { FunctionComponent } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { compose } from "recompose";

import { DropdownField } from "nvent-web/components/form/DropdownField";

interface AreaTypeDropdownFieldProps {
  className?: string;
  required?: boolean;
}

interface AreaTypeDropdownFieldPropsInner extends AreaTypeDropdownFieldProps, InjectedIntlProps {}

const AreaTypeDropdownFieldInner: FunctionComponent<AreaTypeDropdownFieldPropsInner> = ({
  intl,
  className,
  required = false,
}) => {
  const areaTypes = [
    {
      key: "wet",
      title: intl.formatMessage({ id: "room.areaType.wet" }),
    },
    {
      key: "dry",
      title: intl.formatMessage({ id: "room.areaType.dry" }),
    },
  ];

  return (
    <div className={className}>
      <DropdownField
        name="areaType"
        label={<FormattedMessage id="room.areaType" />}
        options={areaTypes}
        placeholder={intl.formatMessage({ id: "room.chooseAreaType" })}
        required={required}
      />
    </div>
  );
};

export const AreaTypeDropdownField = compose<AreaTypeDropdownFieldPropsInner, AreaTypeDropdownFieldProps>(injectIntl)(
  AreaTypeDropdownFieldInner
);
