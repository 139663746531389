import { decamelizeKeys } from "humps";

import { BuildingType } from "nvent-web/types/BuildingType";
import { NewProductSpec } from "nvent-web/types/NewProductSpec";
import { Product } from "nvent-web/types/Product";
import { CategoryRecommendation } from "nvent-web/types/ProductRecommendation";
import { SelectionGuideFormValues } from "nvent-web/types/SelectionGuideFormValues";

import Resource from "./Resource";

export interface GetIndependentRecommendationsPayload extends Omit<SelectionGuideFormValues, "quickHeating"> {
  countryCode: string | null;
  buildingType: BuildingType | null;
  quickHeating?: boolean;
}

export interface BOMProductSku extends Omit<NewProductSpec, "length"> {
  length?: number;
}
interface DownloadProductOnlyBOMPayload {
  countryCode: string;
  productSkus: BOMProductSku[];
  floorConstruction: string;
  floorFinish: string;
  area: number;
  installableArea: number;
  roomType: string;
  locale: string;
  comments: string;
  projectName?: string;
}

export default class ProductResource extends Resource {
  createSkus(projectId: number, roomId: number, specs: NewProductSpec[]) {
    return this.http.post<void>(`projects/${projectId}/rooms/${roomId}/product_skus`, { productSkus: specs });
  }

  createSku(projectId: number, roomId: number, spec: NewProductSpec) {
    return this.createSkus(projectId, roomId, [spec]);
  }

  getOne(projectId: number, roomId: number, productId: number) {
    return this.http.get<Product>(`projects/${projectId}/rooms/${roomId}/products/${productId}`);
  }

  getRecomendations(projectId: number, roomId: number) {
    return this.http.get<CategoryRecommendation[]>(`projects/${projectId}/rooms/${roomId}/product_sku_recommendations`);
  }

  getIndependentRecommendations({ quickHeating = false, ...payload }: GetIndependentRecommendationsPayload) {
    return this.http.get<CategoryRecommendation[]>(`/selection_guide`, {
      params: decamelizeKeys({ quickHeating, ...payload }),
    });
  }

  test(
    projectId: number,
    roomId: number,
    productId: number,
    heatResistance: number,
    insulationResistance: number,
    testType: string
  ) {
    return this.http.post<Product>(`projects/${projectId}/rooms/${roomId}/products/${productId}/tests`, {
      heatResistance,
      insulationResistance,
      testType,
    });
  }

  downloadProductOnlyBOM(payload: DownloadProductOnlyBOMPayload) {
    return this.http.post<string | Blob>("bill_of_materials", decamelizeKeys(payload), { responseType: "blob" });
  }

  downloadPublicProductOnlyBOM(payload: DownloadProductOnlyBOMPayload) {
    return this.http.post<string | Blob>("public/bill_of_materials", decamelizeKeys(payload), { responseType: "blob" });
  }

  removeProduct(projectId: number, roomId: number, productId: number) {
    return this.http.delete(`projects/${projectId}/rooms/${roomId}/products/${productId}`);
  }

  deleteAllProducts(projectId: number, roomId: number) {
    return this.http.delete(`projects/${projectId}/rooms/${roomId}/destroy_all`);
  }
}
