import { inject, observer } from "mobx-react";
import { Component } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import MediaQuery from "react-responsive";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";

import PageTitle from "nvent-web/App/components/PageTitle";
import LinkButton from "nvent-web/components/Button/LinkButton";
import { SearchInput } from "nvent-web/components/form/SearchInput";
import { ProjectList } from "nvent-web/components/ProjectList";
import { gridBreakpoints } from "nvent-web/lib/constants";
import { ProjectsStore } from "nvent-web/stores/Projects";
import { UserStore } from "nvent-web/stores/User";

import ProjectsTabs from "./components/ProjectsTabs";
import style from "./OpenProjects.module.scss";

interface OpenProjectsProps extends RouteComponentProps, InjectedIntlProps {
  user: UserStore;
  projects: ProjectsStore;
}

class OpenProjects extends Component<OpenProjectsProps> {
  private search = this.props.projects.buildSearch({
    status: "open",
    owner: "my",
  });

  render() {
    const { intl } = this.props;

    const {
      user,
      projects: { projects },
    } = this.props;

    const teamProjects = projects.filter(({ finished }) => !finished);
    const myProjects = teamProjects.filter(({ installerId }) => installerId === user.id);
    return (
      <>
        <PageTitle hideBackButton>
          <FormattedMessage id="projects.open.title" />
        </PageTitle>

        <ProjectsTabs
          selected={this.search.owner}
          onSelect={this.search.ownerChanged}
          counts={{
            my: myProjects.length,
            team: teamProjects.length,
          }}
          classNames={{ tabs: style.tabsWrapper }}
        />

        <div className={style.addWrapper}>
          <MediaQuery minWidth={gridBreakpoints.lg}>
            {(isDesktop) => (
              <LinkButton to="/projects/new" theme={isDesktop ? "primaryBlue" : "secondaryBlue"} fixedWidth>
                <FormattedMessage id="actions.add" />
              </LinkButton>
            )}
          </MediaQuery>
        </div>

        <SearchInput
          name="q"
          placeholder={intl.formatMessage({ id: "projects.open.search" })}
          onSearch={this.search.searchChanged}
          className={{ wrapper: style.searchWrapper }}
        />

        <div className={style.listWrapper}>
          <ProjectList
            projects={this.search.results}
            isLoading={!this.search.results.length && this.search.areResultsLoading}
            emptyMessage={<FormattedMessage id="projects.noContent" />}
          />
        </div>
      </>
    );
  }
}

export default compose<OpenProjectsProps, Record<string, unknown>>(
  injectIntl,
  inject("user", "projects"),
  observer
)(OpenProjects);
