import { ButtonHTMLAttributes, FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";

import { LoadingSpinner } from "../LoadingSpinner";

export interface GetPDFActionProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
}

export const GetPDFAction: FunctionComponent<GetPDFActionProps> = ({ className, loading = false, ...buttonProps }) => (
  <button {...buttonProps} type="button" className={className} disabled={loading}>
    {loading ? <LoadingSpinner small /> : <FormattedMessage id="actions.getPDF" />}
  </button>
);
