import { registerLocale } from "i18n-iso-countries";
import { addLocaleData } from "react-intl";

import * as cs from "./locales/cs";
import * as en from "./locales/en";
import * as fi from "./locales/fi";
import * as fr from "./locales/fr";
import * as nn from "./locales/nn";
import * as pl from "./locales/pl";
import * as ru from "./locales/ru";
import * as sv from "./locales/sv";

import "./yup";

addLocaleData(cs.data);
addLocaleData(en.data);
addLocaleData(fr.data);
addLocaleData(sv.data);
addLocaleData(nn.data);
addLocaleData(ru.data);
addLocaleData(pl.data);
addLocaleData(fi.data);

registerLocale(cs.countries);
registerLocale(en.countries);
registerLocale(fr.countries);
registerLocale(sv.countries);
registerLocale(nn.countries);
registerLocale(ru.countries);
registerLocale(pl.countries);
registerLocale(fi.countries);

export const messages = {
  cs: cs.messages,
  en: en.messages,
  fr: fr.messages,
  sv: sv.messages,
  nn: nn.messages,
  pl: pl.messages,
  fi: fi.messages,
  ru: ru.messages,
};

// It's generally not a great idea to translate language names,
// having the language label in that language makes sure the user
// can easily switch back to it.
const labels = {
  cs: "Čeština",
  en: "English",
  fr: "Français",
  sv: "Svenska",
  nn: "Norsk",
  pl: "Polski",
  fi: "Suomi",
  ru: "Русский",
};

export type KnownLocale = keyof typeof messages;
export const knownLocales = Object.keys(messages) as KnownLocale[];
export const knownLocaleOptions = Object.entries(labels).map(([key, title]) => ({ key, title }));

export function isKnownLocale(locale: string): locale is KnownLocale {
  return knownLocales.includes(locale as KnownLocale);
}
