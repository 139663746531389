import { DetailedRoom } from "nvent-web/types/DetailedRoom";
import { NewRoomFormValues } from "nvent-web/types/NewRoomFormValues";
import { Room } from "nvent-web/types/Room";

import Resource from "./Resource";

export default class RoomsResource extends Resource {
  create(data: NewRoomFormValues, projectId: number) {
    return this.http.post<Room>(`projects/${projectId}/rooms`, data);
  }
  getOne(projectId: number, roomId: number) {
    return this.http.get<DetailedRoom>(`projects/${projectId}/rooms/${roomId}`);
  }
  remove(projectId: number, roomId: number) {
    return this.http.delete(`projects/${projectId}/rooms/${roomId}`);
  }
  updateOne(data: Partial<NewRoomFormValues>, projectId: number, roomId: number) {
    return this.http.patch<Room>(`projects/${projectId}/rooms/${roomId}`, data);
  }
  copy(projectId: number, roomId: number) {
    return this.http.post(`projects/${projectId}/rooms/${roomId}/duplicate`);
  }
}
