import { History } from "history";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import { Component } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import PageTitle from "nvent-web/App/components/PageTitle";
import ProjectForm from "nvent-web/components/ProjectForm";
import Api from "nvent-web/services/Api";
import { NotificationsStore } from "nvent-web/stores/Notifications";
import { ProjectsStore } from "nvent-web/stores/Projects";
import { NewProjectFormValues } from "nvent-web/types/NewProjectFormValues";
import { clearAwaitingProject, getAwaitingProject } from "nvent-web/utils/awaitingProject";

import style from "./ContinueCreatingProject.module.scss";

const emptyValues: NewProjectFormValues = {
  project: {
    name: "",
    addressAttributes: {
      streetAddress: "",
      postalCode: "",
      locality: "",
    },
    clientName: "",
    clientCompany: "",
    clientEmail: "",
    clientPhoneNumber: "",
  },
};

interface ContinueCreatingProjectInnerProps extends InjectedIntlProps {
  projects: ProjectsStore;
  api: Api;
  notifications: NotificationsStore;
  history: History;
}

class ContinueCreatingProjectInner extends Component<ContinueCreatingProjectInnerProps> {
  @observable
  private initialValues?: NewProjectFormValues;

  @observable
  private isSubmitting = false;

  componentDidMount() {
    const awaitingProject = getAwaitingProject();

    if (awaitingProject) {
      this.initialValues = {
        project: {
          ...emptyValues.project,
          name: awaitingProject.roomDetails.projectName || "",
        },
      };
    }
  }

  render() {
    if (!this.initialValues) {
      return null;
    }

    return (
      <div className={style.container}>
        <PageTitle>
          <FormattedMessage id="projects.continue.title" />
        </PageTitle>
        <ProjectForm
          handleSubmit={this.handleSubmit}
          initialValues={this.initialValues}
          onCancel={this.handleCancel}
          isSubmitting={this.isSubmitting}
        />
      </div>
    );
  }

  private handleSubmit = async (values: NewProjectFormValues) => {
    this.isSubmitting = true;
    const { api, projects } = this.props;

    try {
      const awaitingProject = getAwaitingProject();

      if (awaitingProject) {
        const { id: projectId } = await api.projects.create(values).then(({ data }) => data);
        const { id: roomId } = await api.rooms
          .create(
            {
              name: this.props.intl.formatMessage({ id: "room.defaultName" }),
              ...awaitingProject.roomDetails,
            },
            projectId
          )
          .then(({ data }) => data);

        await api.products.createSkus(projectId, roomId, awaitingProject.productSkus);

        clearAwaitingProject();
        projects.loadProjects();
        this.props.history.push(`/projects/${projectId}/rooms/${roomId}`);
      }
    } catch (error) {
      this.props.notifications.createError(<FormattedMessage id="error.somethingWentWrong" />);
    } finally {
      this.isSubmitting = false;
    }
  };

  private handleCancel = () => {
    clearAwaitingProject();
    this.props.history.replace(`/projects/open`);
  };
}

export const ContinueCreatingProject = compose<ContinueCreatingProjectInnerProps, Record<string, unknown>>(
  inject("projects", "notifications", "api"),
  injectIntl,
  withRouter,
  observer
)(ContinueCreatingProjectInner);
