import { Component } from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import MediaQuery from "react-responsive";

import DefaultPhoto from "nvent-web/assets/png/no-photo.png";
import Card from "nvent-web/components/Card";
import DesktopCard from "nvent-web/components/Card/components/DesktopCard";
import { gridBreakpoints } from "nvent-web/lib/constants";
import { DetailedRoom } from "nvent-web/types/DetailedRoom";

import style from "./RoomCard.module.scss";

interface RoomCardProps {
  project: DetailedRoom;
  projectId: number;
  actions: React.ReactNode[];
  toggleExpanded: () => void;
  isExpanded: boolean;
  isFinished: boolean;
}

class RoomCard extends Component<RoomCardProps> {
  renderCardDetails() {
    const { installableArea, products, totalOutput = "" } = this.props.project;

    const cableLength = products.reduce((sum, current) => {
      return sum + (current.cableLength || 0);
    }, 0);

    return (
      <>
        <div className={style.details}>
          <div className={style.detailsItem}>
            <div className={style.detailsItemLabel}>
              <FormattedHTMLMessage id="roomCard.details.area" />
            </div>
            <div className={style.detailsItemValue}>{this.renderArea(installableArea)}</div>
          </div>
          <div className={style.detailsItem}>
            <div className={style.detailsItemLabel}>
              <FormattedMessage id="roomCard.details.products" />
            </div>
            <div className={style.detailsItemValue}>{products.length}</div>
          </div>
          <div className={style.detailsItem}>
            <div className={style.detailsItemLabel}>
              <FormattedHTMLMessage id="roomCard.details.powerOutage" />
            </div>
            <div className={style.detailsItemValue}>{totalOutput}W</div>
          </div>
          <div className={style.detailsItem}>
            <div className={style.detailsItemLabel}>
              <FormattedHTMLMessage id="roomCard.details.cableLength" />
            </div>
            <div className={style.detailsItemValue}>{cableLength}&nbsp;m</div>
          </div>
        </div>
      </>
    );
  }
  render() {
    const { id, name, progress } = this.props.project;
    const { projectId, actions, isExpanded, toggleExpanded, isFinished } = this.props;

    return (
      <>
        <MediaQuery minWidth={gridBreakpoints.lg}>
          {(isDesktop) => {
            if (isDesktop) {
              return (
                <DesktopCard
                  to={`/projects/${projectId}/rooms/${id}`}
                  actions={actions}
                  progress={progress}
                  className={{ title: style.desktopCardTitle }}
                  title={name}
                  isFinished={isFinished}
                >
                  {this.renderCardDetails()}
                </DesktopCard>
              );
            } else {
              return (
                <Card
                  to={`/projects/${projectId}/rooms/${id}`}
                  title={this.renderTitle()}
                  subtitle={this.renderSubtitle()}
                  actions={actions}
                  progress={progress}
                  classNames={{ header: style.cardHeader }}
                  toggleExpanded={toggleExpanded}
                  isExpanded={isExpanded}
                  isFinished={isFinished}
                >
                  {this.renderCardDetails()}
                </Card>
              );
            }
          }}
        </MediaQuery>
      </>
    );
  }

  private renderArea(area: number) {
    return area > 0 ? (
      <>
        {area}&nbsp;m<sup>2</sup>
      </>
    ) : (
      "-"
    );
  }

  private renderTitle() {
    const {
      project: { name, photosCount },
    } = this.props;

    return (
      <div className={style.roomName}>
        {name} {!photosCount && <img alt="" className={style.noImage} src={DefaultPhoto} />}
      </div>
    );
  }

  private renderSubtitle() {
    const { progress, photosCount } = this.props.project;

    return (
      <>
        <FormattedMessage id="roomCard.details.completedTests" values={{ percent: progress * 100 }} />
        {!photosCount && (
          <>
            , <FormattedMessage id="roomCard.details.missingPhotos" />
          </>
        )}
      </>
    );
  }
}

export default RoomCard;
