import { Form, Formik, FormikActions, FormikErrors, FormikProps, validateYupSchema, yupToFormErrors } from "formik";
import { History } from "history";
import humps from "humps";
import { observable, reaction, toJS } from "mobx";
import { observer } from "mobx-react";
import { Component, ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router";
import { compose } from "recompose";
import * as Yup from "yup";

import { TextField } from "nvent-web/components/form/TextField";
import { HelpTip } from "nvent-web/components/HelpTip";
import SubmitCancelButtons from "nvent-web/components/SubmitCancelButtons";
import { Product } from "nvent-web/types/Product";

import style from "./Test.module.scss";

interface TestProps {
  product: Product;
  onSubmit: (values: TestFormValues, step: string) => Promise<void>;
  step: string;
}

export interface TestFormValues {
  heatResistance: number;
  insulationResistance: number;
}

type TestFormRawValues = { [K in keyof TestFormValues]: string };

interface TestInnerProps extends TestProps {
  history: History;
}

const INSULATION_RESISTANCE_MIN = 100;

class Test extends Component<TestInnerProps> {
  // Test results that don't meet the product specification should be submittable,
  // so we have 2 separate schemas, one to check if the values are good enough
  // to submit and the second one to check if they meet the spec.
  @observable private submitSchema!: Yup.ObjectSchema<TestFormValues>;
  @observable private passSchema!: Yup.ObjectSchema<TestFormValues>;
  @observable private passErrors: FormikErrors<TestFormRawValues> = {};

  constructor(props: TestInnerProps) {
    super(props);

    reaction(
      () => this.props.product,
      (product) => {
        const insulationResistance = Yup.number().required();
        const heatResistance =
          product.minResistance && product.maxResistance ? Yup.number().required() : Yup.number().default(0).required();

        this.submitSchema = Yup.object({
          heatResistance,
          insulationResistance,
        }).required();
        this.passSchema = Yup.object({
          heatResistance: heatResistance.min(product.minResistance).max(product.maxResistance),
          insulationResistance: insulationResistance.min(INSULATION_RESISTANCE_MIN),
        }).required();
      },
      { fireImmediately: true }
    );
  }

  render() {
    const { product, step } = this.props;

    if (!step) {
      return null;
    }

    const passErrors = toJS(this.passErrors);

    const initialValues: TestFormRawValues = {
      heatResistance: "",
      insulationResistance: "",
    };

    return (
      <Formik initialValues={initialValues} validate={this.validate} onSubmit={this.handleSubmit}>
        {(formik) => (
          <TestForm
            title={<FormattedMessage id={`tests.${humps.camelize(step)}.title`} />}
            product={product}
            formik={formik}
            passErrors={passErrors}
            onCancel={this.handleCancel}
          />
        )}
      </Formik>
    );
  }

  private validate = async (rawValues: TestFormRawValues) => {
    const [submitError, passError] = await Promise.all([
      validateYupSchema(rawValues, this.submitSchema)
        .then(() => null)
        .catch((err: Yup.ValidationError) => err),
      validateYupSchema(rawValues, this.passSchema)
        .then(() => null)
        .catch((err: Yup.ValidationError) => err),
    ]);

    this.passErrors = passError ? yupToFormErrors<TestFormRawValues>(passError) : {};

    if (submitError) {
      throw yupToFormErrors<TestFormRawValues>(submitError);
    }
  };

  private handleSubmit = async (rawValues: TestFormRawValues, actions: FormikActions<TestFormRawValues>) => {
    const { step, onSubmit } = this.props;

    const values = this.submitSchema.cast({
      ...rawValues,
      heatResistance: rawValues.heatResistance || undefined,
    });

    try {
      await onSubmit(values, step);
      actions.resetForm();
    } finally {
      actions.setSubmitting(false);
    }
  };

  private handleCancel = () => {
    this.props.history.goBack();
  };
}

export default compose<TestInnerProps, TestProps>(withRouter, observer)(Test);

interface TestFormProps {
  title: ReactNode;
  product: Product;
  formik: FormikProps<TestFormRawValues>;
  passErrors: FormikErrors<TestFormRawValues>;
  onCancel(): void;
}

class TestForm extends Component<TestFormProps> {
  render() {
    const { title, product, passErrors } = this.props;
    const { touched, isValid, isSubmitting } = this.props.formik;
    const isHeatResistanceRequired = Boolean(product.minResistance && product.maxResistance);

    return (
      <Form>
        <h3 className={style.title}>{title}</h3>

        {isHeatResistanceRequired && (
          <TextField
            type="tel"
            name="heatResistance"
            hideErrorMessage
            stateColor={touched.heatResistance}
            showMark={touched.heatResistance}
            success={!passErrors.heatResistance}
            label={
              <div className={style.labelWrapper}>
                <div className={style.labelInner}>
                  <h4 className={style.heatResistance}>
                    <FormattedMessage id="form.heatResistance" />
                  </h4>
                  <FormattedMessage
                    id="form.heatResistance.range"
                    values={{ min: product.minResistance, max: product.maxResistance }}
                  />
                </div>
                <HelpTip direction="up">
                  <FormattedMessage id="tests.tooltip.heatResistance" />
                </HelpTip>
              </div>
            }
          />
        )}

        <TextField
          type="tel"
          name="insulationResistance"
          hideErrorMessage
          stateColor={touched.insulationResistance}
          showMark={touched.insulationResistance}
          success={!passErrors.insulationResistance}
          label={
            <div className={style.labelWrapper}>
              <div className={style.labelInner}>
                <h4 className={style.heatResistance}>
                  <FormattedMessage id="form.insulationResistance" />
                </h4>
                <FormattedMessage id="form.insulationResistance.range" />
              </div>
              <HelpTip direction="up">
                <FormattedMessage id="tests.tooltip.insulationResistance" />
              </HelpTip>
            </div>
          }
        />

        <div className={style.actions}>
          <SubmitCancelButtons
            cancelLabel={<FormattedMessage id="actions.back" />}
            onCancel={this.props.onCancel}
            loading={isSubmitting}
            disabled={!isValid || isSubmitting}
          />
        </div>
      </Form>
    );
  }
}
