import { Form, Formik } from "formik";
import { inject, observer } from "mobx-react";
import { Component } from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import { compose } from "recompose";
import * as Yup from "yup";

import { AreaTypeDropdownField } from "nvent-web/components/AreaTypeDropdownField/AreaTypeDropdownField";
import { TextField } from "nvent-web/components/form/TextField";
import { RequiredFieldLabel } from "nvent-web/components/RequiredFieldLabel";
import SubmitCancelButtons from "nvent-web/components/SubmitCancelButtons";
import { buildMessage } from "nvent-web/i18n/yup";
import AppStore from "nvent-web/stores/App";
import { RoomsStore } from "nvent-web/stores/Rooms";
import { NewRoomFormValues, RawNewRoomFormValues } from "nvent-web/types/NewRoomFormValues";

import FloorConstructionField from "../FloorConstructionField/FloorConstructionField";
import FloorFinishField from "../FloorFinishField/FloorFinishField";
import RoomAreas from "../RoomAreas/RoomAreas";

import style from "./RoomForm.module.scss";

interface RoomFormProps {
  onCancel: () => void;
  initialValues: NewRoomFormValues;
  handleSubmit: (formValues: NewRoomFormValues) => void;
  isSubmitting: boolean;
}

interface RoomFormInnerProps extends RoomFormProps, InjectedIntlProps {
  app: AppStore;
  rooms: RoomsStore;
}

const areaSchema = Yup.number()
  .transform((_, value: string | undefined) =>
    value === undefined || value === "" ? null : Number(value.replace(",", "."))
  )
  .nullable()
  .typeError(buildMessage("error.number.positive"))
  .positive();
class RoomFormInner extends Component<RoomFormInnerProps> {
  schema: Yup.Schema<NewRoomFormValues> = Yup.object({
    name: Yup.string().required(),
    area: areaSchema,
    installableArea: areaSchema.when("area", (area: number | null, schema: typeof areaSchema) =>
      area === null ? schema : schema.max(area, buildMessage("room.error.installableArea.max"))
    ),
    floorConstruction: Yup.string().nullable(),
    floorFinish: Yup.string().nullable(),
    areaType: Yup.string().nullable(),
  }).required();

  render() {
    const { onCancel, initialValues, isSubmitting } = this.props;

    const rawInitialValues: RawNewRoomFormValues = {
      name: initialValues.name,
      area: initialValues.area ? initialValues.area.toString() : "",
      installableArea: initialValues.installableArea ? initialValues.installableArea.toString() : "",
      floorConstruction: initialValues.floorConstruction || "",
      floorFinish: initialValues.floorFinish || "",
      areaType: initialValues.areaType || "",
    };

    return (
      <>
        <Formik validationSchema={this.schema} initialValues={rawInitialValues} onSubmit={this.handleSubmit}>
          {({ initialValues: { floorFinish } }) => (
            <Form noValidate={true} className={style.wrapper}>
              <div className={style.column}>
                <TextField name="name" label={<FormattedMessage id="form.roomName" />} required />
                <RoomAreas />
              </div>

              <div className={style.column}>
                <FloorConstructionField />
                <FloorFinishField initialValue={floorFinish} />
                <AreaTypeDropdownField />
              </div>
              <div className={style.column}>
                <RequiredFieldLabel />
              </div>
              <div className={style.column}>
                <div className={style.actions}>
                  <SubmitCancelButtons disabled={isSubmitting} loading={isSubmitting} onCancel={onCancel} />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }

  private handleSubmit = (rawValues: RawNewRoomFormValues) => {
    const value = this.schema.cast(rawValues);
    this.props.handleSubmit(value);
  };
}

const RoomForm = compose<RoomFormInnerProps, RoomFormProps>(
  injectIntl,
  inject("app", "rooms"),
  observer
)(RoomFormInner);

export default RoomForm;
