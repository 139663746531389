import { History } from "history";
import { action, autorun, computed, observable } from "mobx";
import { fromPromise, IPromiseBasedObservable, PENDING } from "mobx-utils";

import Api from "nvent-web/services/Api";
import { DetailedRoom } from "nvent-web/types/DetailedRoom";
import { FloorFinishOption } from "nvent-web/types/FloorFInishOption";
import { NewRoomFormValues } from "nvent-web/types/NewRoomFormValues";
import { Option } from "nvent-web/types/Option";
import { Room } from "nvent-web/types/Room";
import { createInitialFromPromise } from "nvent-web/utils/createInitialFromPromise";
export class RoomLocalState {
  @observable
  isExpanded = false;

  constructor(roomId: number) {
    const key = `rooms.${roomId}.state`;
    const stored = localStorage.getItem(key);

    if (stored) {
      Object.assign(this, JSON.parse(stored));
    }

    autorun(() => {
      localStorage.setItem(key, JSON.stringify({ isExpanded: this.isExpanded }));
    });
  }

  @action.bound
  toggleExpanded(isExpanded = !this.isExpanded) {
    this.isExpanded = isExpanded;
  }
}

export class RoomsStore {
  @observable newRoomPromise?: IPromiseBasedObservable<Room>;
  @observable roomDetailsPromise?: IPromiseBasedObservable<DetailedRoom>;
  @observable roomDetails?: DetailedRoom;
  @observable removeRoomPromise?: IPromiseBasedObservable<void>;
  @observable copyRoomPromise?: IPromiseBasedObservable<void>;
  @observable floorFinishOptionsPromise = createInitialFromPromise<FloorFinishOption[]>([]);
  @observable floorConstructionOptionsPromise = createInitialFromPromise<Option[]>([]);

  private localStateMap = observable.map<number, RoomLocalState>();

  constructor(
    private api: Api,
    private history: History
  ) {
    this.loadFloorFinishOptions();
    this.loadFloorConstructionOptions();

    autorun(() => {
      if (this.roomDetailsPromise) {
        this.roomDetails = this.roomDetailsPromise.case({ fulfilled: (r) => r }) || this.roomDetails;
      }
    });
  }

  @action.bound
  getLocalState(id: number): RoomLocalState {
    let state = this.localStateMap.get(id);

    if (!state) {
      state = new RoomLocalState(id);
      this.localStateMap.set(id, state);
    }

    return state;
  }

  @computed
  get isRoomCreating(): boolean {
    return Boolean(this.newRoomPromise && this.newRoomPromise.state === PENDING);
  }

  @action.bound
  async createRoom(values: NewRoomFormValues, projectId: number) {
    this.newRoomPromise = fromPromise(this.api.rooms.create(values, projectId).then(({ data }) => data));
    const room = await this.newRoomPromise;

    this.history.push(`/projects/${projectId}/rooms/${room.id}`);
  }

  @computed
  get areRoomDetailsLoading(): boolean {
    return Boolean(this.roomDetailsPromise && this.roomDetailsPromise.state === PENDING);
  }

  @action.bound
  async getRoom(projectId: number, roomId: number) {
    this.roomDetailsPromise = fromPromise(this.api.rooms.getOne(projectId, roomId).then(({ data }) => data));
    return this.roomDetailsPromise;
  }

  @action.bound
  async removeRoom(projectId: number, roomId: number) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    this.removeRoomPromise = fromPromise(this.api.rooms.remove(projectId, roomId).then(({ data }) => data));
    return this.removeRoomPromise;
  }

  @action.bound
  async copyRoom(projectId: number, roomId: number) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    this.copyRoomPromise = fromPromise(this.api.rooms.copy(projectId, roomId).then(({ data }) => data));
    return this.copyRoomPromise;
  }

  @action.bound
  async updateRoom(values: Partial<NewRoomFormValues>, projectId: number, id: number) {
    this.newRoomPromise = fromPromise(this.api.rooms.updateOne(values, projectId, id).then(({ data }) => data));

    await this.newRoomPromise;
  }

  @computed
  get areFloorFinishOptionsLoading(): boolean {
    return this.floorFinishOptionsPromise.state === PENDING;
  }

  @computed
  get areFloorContructionOptionsLoading(): boolean {
    return this.floorConstructionOptionsPromise.state === PENDING;
  }

  @action.bound
  loadFloorFinishOptions() {
    this.floorFinishOptionsPromise = fromPromise(this.api.options.getFloorFinish().then(({ data }) => data));
  }

  @action.bound
  loadFloorConstructionOptions() {
    this.floorConstructionOptionsPromise = fromPromise(
      this.api.options.getFloorConstruction().then(({ data }) => data)
    );
  }

  @computed
  get floorFinishOptions(): FloorFinishOption[] {
    return (
      this.floorFinishOptionsPromise.case({
        fulfilled: (floors) => floors,
      }) || []
    );
  }

  @computed
  get floorConstructionOptions(): Option[] {
    return (
      this.floorConstructionOptionsPromise.case({
        fulfilled: (floors) => floors,
      }) || []
    );
  }
}
